<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="col-span-6" style="background:#FFF;padding:20px;">
              <Principal/>
            </div>
        </div>
      </body>
    </html>
</template>


<script>
import Principal from '@/components/Advisers/Principal';
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateral from '@/components/LateralMenu.vue';
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import Footer from '@/components/Analyst/Footer.vue';
export default {
  name: 'AssistedEnrollmentStep1View',
  components: {
    Principal,
    Header,
    MenuLateral,
    MenuLateralMobile,
    Footer
  }
}
</script>
